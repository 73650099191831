import domready from 'domready';
import React, { Suspense, lazy } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router  } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import store from './store';


const loadEnvConfig = (): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script');
    script.src = `/env-config.js`; 
    script.onload = () => resolve();
    script.onerror = (event) => {
      console.error('Failed to load env-config.js', event);
      reject(new Error('Failed to load env-config.js'));
    };
    document.head.appendChild(script);
  });
};

const run = () => {
  const App = lazy(() => import('./App'));

  render(
    <Suspense fallback={null}>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </Suspense>,
    document.getElementById('root')
  );
};

domready(() => {
  loadEnvConfig()
    .then(run)
    .catch((error) => {
      console.error(error);
    });
});
